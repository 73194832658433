export const ReceiptOfYourPaymentText = {
    en: 'Receipt of your payment',
    es: 'Recibo de pago',
};

const waitingInput = {
    en: 'Pending transaction.',
    es: 'Transacción pendiente.'
};

const authorizeFailed = {
    en: 'Responsible gambling limit breached.',
    es: 'DEVUELTO: Se ha superado el límite de juego responsable',
};

const errorLimit = {
    es: 'Devuelto: Fuera de límites',
};

export default {
    status: {
        en: 'status',
    },
    ReceiptOfYourPaymentText,
    SUCCESS: {
        en: 'Success',
        es: 'Exitoso',
    },
    AUTHORIZE_FAILED: authorizeFailed,
    AUTHORIZE_FAILED_LIMIT_BROKEN: authorizeFailed,
    WAITING_INPUT: waitingInput,
    PENDING_BY_PROVIDER: waitingInput,
    ERROR_BELOW_LIMIT: errorLimit,
    ERROR_ABOVE_LIMIT: errorLimit,
    AUTHORIZE_FAILED_USER_NOT_VALIDATED: {
        es: 'El usuario no está validado',
    },
    AUTHORIZE_FAILED_PAYMENT_DISABLED: {
        es: 'pago deshabilitado',
    },
    AUTHORIZE_FAILED_WITHDRAW_NOTALLOWED: {
        es: 'retirar no permitido',
    },
    AUTHORIZE_FAILED_DEPOSIT_NOTALLOWED: {
        es: 'depósito no permitido',
    },
    ERR_DECLINED_OTHER_REASON: {
        es: "La tarjeta ha sido rechazada, inténtalo con una distinta."
    },
}